import PropTypes from 'prop-types';
import React, { Component } from 'react';
import newIcon from './new.svg';
import styles from './PreviewBadges.module.scss';
import PremiumBadge from './PremiumBadge.svg';
import UnlockedBadge from '../UnlockedBadge.svg';
import DraftBadge from './DraftBadge.svg';
import ScheduledBadge from './ScheduledBadge.svg';
import DateBadge from './DateBadge';

export default class PreviewBadges extends Component {
    render() {
        const { mockup, isUserPremium, isUserInternal, isUserScheduled } = this.props;

        const badges = [];

        const publishDate = new Date(mockup.publishDate);
        const now = new Date();
        const twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

        if (publishDate > twoWeeksAgo.getTime() && publishDate < now.getTime()) {
            badges.push(
                <img
                    src={newIcon}
                    className={styles.badge}
                    key={`${mockup.id}-new-badge`}
                    alt="new"
                />
            );
        }

        if (mockup.isPremium && !isUserPremium) {
            badges.push(
                <img
                    src={PremiumBadge}
                    className={styles.badge}
                    alt="Pro only"
                    title="Pro mockup"
                    key={`${mockup.id}-premium-badge`}
                />
            );
        } else if (mockup.isPremium) {
            badges.push(
                <img
                    src={UnlockedBadge}
                    className={styles.badge}
                    alt="Mockup unlocked"
                    title="Pro mockup unlocked"
                    key={`${mockup.id}-premium-badge`}
                />
            );
        }

        if (isUserInternal && mockup.isDraft) {
            badges.push(
                <img
                    src={DraftBadge}
                    className={styles.badge}
                    alt="Mockup draft"
                    title="Mockup draft"
                    key={`${mockup.id}-draft-badge`}
                />
            );
        } else if ((isUserInternal || isUserScheduled) && mockup.publishDate > now.getTime()) {
            badges.push(
                <img
                    src={ScheduledBadge}
                    className={styles.badge}
                    alt="Mockup scheduled"
                    title="Mockup scheduled"
                    key={`${mockup.id}-scheduled-badge`}
                />
            );
            badges.push(
                <DateBadge date={mockup.publishDate} key={`${mockup.id}-scheduled-badge-date`} />
            );
        }

        if (badges.length === 0) return null;

        return <div className={styles.badges}>{badges}</div>;
    }
}

PreviewBadges.propTypes = {
    isUserInternal: PropTypes.bool,
    isUserPremium: PropTypes.bool,
    isUserScheduled: PropTypes.bool,
    mockup: PropTypes.object
};
