import React from 'react';
import styles from './PreviewBadges.module.scss';

const DateBadge = props => {
    const date = new Date(props.date);
    return (
        <span className={styles.scheduledBadge}>
            {date.getDate()}.{date.getMonth() + 1}
        </span>
    );
};

export default DateBadge;
